import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout/index"
import cn from "classnames"
import REQUIRED_IMG from "../images/required.svg"
import Data from "../data/data"
import style from "./contact.module.css"
import { graphql } from 'gatsby';

// SELECT OPTIONS
const SECTOR_OPTIONS = [
  { label: "Sector", value: "" },
  { label: "Freight Forwarder", value: "Freight Forwarder" },
  { label: "Ship Owner", value: "Ship Owner" },
  { label: "Ship Operator", value: "Ship Operator" },
  { label: "Charterer", value: "Charterer" },
  { label: "Broker", value: "Broker" },
  { label: "Investor", value: "Investor" },
  { label: "Other", value: "Other" },
]

export const query = graphql`
  query EnvQuery {
    site {
      siteMetadata {
        apiServer
      }
    }
  }
`;

class ContactPage extends React.Component {
  state = {
    name: "",
    email: "",
    company: "",
    companyType: "",
    phone: "",
    message: "",
    nameFocused: false,
    emailFocused: false,
    phoneFocused: false,
    messageFocused: false,
    companyFocused: false,
  }

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSelectChange = e => {
    this.setState({
      companyType: e.target.value,
    })
  }

  nameFocus = () => {
    this.setState({
      nameFocused: true,
    })
  }
  nameBlur = () => {
    this.setState({
      nameFocused: false,
    })
  }

  emailFocus = () => {
    this.setState({
      emailFocused: true,
    })
  }
  emailBlur = () => {
    this.setState({
      emailFocused: false,
    })
  }

  companyFocus = () => {
    this.setState({
      companyFocused: true,
    })
  }
  companyBlur = () => {
    this.setState({
      companyFocused: false,
    })
  }

  phoneFocus = () => {
    this.setState({
      phoneFocused: true,
    })
  }
  phoneBlur = () => {
    this.setState({
      phoneFocused: false,
    })
  }

  messageFocus = () => {
    this.setState({
      messageFocused: true,
    })
  }

  messageBlur = () => {
    this.setState({
      messageFocused: false,
    })
  }

  handleSubmit = e => {
    const apiServer = this.props.data.site.siteMetadata.apiServer;
    e.preventDefault();
    fetch(`${apiServer}/api/Helpers/register`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        signup: {
          name: this.state.name,
          email: this.state.email,
          company: this.state.company,
          companyType: this.state.companyType,
          phone: this.state.phone,
          message: this.state.message,
        },
      }),
      mode: 'cors',
    });
    document.location.href = '/thanks';
  }

  render() {
    const {
      name,
      email,
      company,
      companyType,
      phone,
      message,
      nameFocused,
      emailFocused,
      phoneFocused,
      messageFocused,
      companyFocused,
    } = this.state

    return (
      <>
        <Layout>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{Data.contact.title}</title>
            <meta
              name="description"
              content={Data.metaData.description.contact}
            />
          </Helmet>

          {/* Banner */}
          <div className={style.root}>
            <div className={style.contact__banner}>
              <div className="container">
                <div className={`row ${style.customRow}`}>
                  <div className="col s12">
                    <div className={style.contact__bannerContent}>
                      <h1 className={style.contact__heading}>
                        {Data.contact.heading}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Banner Image */}
          <div className={style.contact__image}></div>

          {/* Form Section */}
          <div className={style.form}>
            <div className="container">
              <div className={`row ${style.customRow}`}>
                <div className="col s12">
                  <div className={style.form__Content}>
                    <h4 className={style.form__heading}>
                      We'd love to hear from you. Get a personal demo or just
                      reach out to start a conversation.
                    </h4>
                    <div className={`row ${style.customRow}`}>
                      <div className="col s12">
                        <form
                          className={style.form__element}
                          onSubmit={this.handleSubmit}
                        >
                          <h4 className={style.form__headingDesktop}>
                            We'd love to hear from you. Get a personal demo{" "}
                            <br />
                            or just reach out to start a conversation.
                          </h4>
                          <div className={style.formGroup}>
                            <input
                              type="text"
                              name="name"
                              value={name}
                              onChange={this.handleInputChange}
                              placeholder="Name"
                              required
                              className={cn(
                                style.formInput,
                                nameFocused && style.focusedInput
                              )}
                              onFocus={this.nameFocus}
                              onBlur={this.nameBlur}
                            />
                            <span className={style.asterisk}>*</span>
                          </div>

                          <div className={style.formGroup}>
                            <input
                              type="email"
                              name="email"
                              value={email}
                              onChange={this.handleInputChange}
                              placeholder="Email"
                              required
                              className={cn(
                                style.formInput,
                                emailFocused && style.focusedInput
                              )}
                              onFocus={this.emailFocus}
                              onBlur={this.emailBlur}
                            />
                            <span className={style.asterisk}>*</span>
                          </div>
                          <div className={style.formGroup}>
                            <input
                              type="text"
                              name="company"
                              value={company}
                              onChange={this.handleInputChange}
                              placeholder="Company"
                              required
                              className={cn(
                                style.formInput,
                                companyFocused && style.focusedInput
                              )}
                              onFocus={this.companyFocus}
                              onBlur={this.companyBlur}
                            />
                            <span className={style.asterisk}>*</span>
                          </div>
                          <select
                            name="companyType"
                            value={companyType}
                            onChange={this.handleInputChange}
                            placeholder="Sector"
                            required
                            className={cn(style.formInput, style.select)}
                          >
                            {SECTOR_OPTIONS.map(sector => {
                              return (
                                <option value={sector.value} key={sector.label}>
                                  {sector.label}
                                </option>
                              )
                            })}
                          </select>
                          <input
                            type="tel"
                            name="phone"
                            value={phone}
                            onChange={this.handleInputChange}
                            placeholder="Phone"
                            className={cn(
                              style.formInput,
                              phoneFocused && style.focusedInput
                            )}
                            onFocus={this.phoneFocus}
                            onBlur={this.phoneBlur}
                          />
                          <textarea
                            name="message"
                            value={message}
                            onChange={this.handleInputChange}
                            placeholder="Message..."
                            className={cn(
                              style.formInput,
                              style.textArea,
                              messageFocused && style.focusedInput
                            )}
                            onFocus={this.messageFocus}
                            onBlur={this.messageBlur}
                          />
                          <div className={style.requiredFieldLabel}>
                            <img
                              className={cn(style.requiredImg)}
                              src={REQUIRED_IMG}
                              alt="Required"
                            />
                          </div>
                          <button type="submit" className={style.formSubmit}>
                            Submit
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

ContactPage.propTypes = {}

export default ContactPage
